// *********** Various imports ***********
// Note: using @use instead of @import as much as possible to reduce sizes
// @use "src/assets/scss/variables" as var;
@use 'sass:map';
@use "@angular/material" as mat;
@use "src/assets/scss/variables" as variables;
@use "src/assets/scss/materialThemeColors" as theming;
@use "src/assets/scss/materialStyling" as matStyling;
@import "material-symbols/outlined.css";
@include mat.core; // Should only be included once
// @use "src/assets/scss/materialThemes" as theme;

// *********** Angular material theming ***********
// For more information: https://material.angular.io/guide/theming

// *********** Tailwind imports & utilities ***********
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light dark;

  // Background color for the whole app
  --background: light-dark(#{variables.$monochrome-white}, #{variables.$monochrome-darkest});

  // Material surface color
  --surface: light-dark(#{variables.$monochrome-lightest}, #{variables.$monochrome-dark-surface});

  @include mat.theme((color: theming.$primary-palette,
      typography: (brand-family: 'Clan OT',
        plain-family: 'Cooper Hewitt',
        bold-weight: 700,
        medium-weight: 500,
        regular-weight: 300),
      density: 0), $overrides: (background: var(--background),
      surface: (var(--surface)),
    ));
}

.material-symbols-outlined {
  // Usage: https://github.com/marella/material-symbols/tree/main/material-symbols#usage
  font-variation-settings: "FILL" 1 "wght" 300;
}

@layer utilities {
  .no-number-arrows {

    /* stylelint-disable-next-line comment-empty-line-before */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      appearance: textfield;
    }
  }

  .icon-fill {
    font-variation-settings: "FILL" 1;
  }

  .icon-no-fill {
    font-variation-settings: "FILL" 0;
  }

  .table-hide-column {
    @apply hidden;
  }

  .table-sm-hide-column {
    @apply max-sm:hidden;
  }

  .table-md-hide-column {
    @apply max-md:hidden;
  }

  .dialog-responsive {
    @apply w-full px-2 max-w-full md:w-3/4 lg:w-3/5 xl:w-1/3 h-fit max-h-[90dvh] #{!important};
  }

  .dialog-responsive-larger {
    @apply w-full px-2 max-w-full md:w-4/5 lg:w-3/4 xl:w-3/5 h-fit max-h-[90dvh] #{!important};
  }

  .dialog-responsive-xl {
    @apply w-full px-2 max-w-full md:w-5/6 lg:w-4/5 h-fit max-h-[90dvh] #{!important};
  }

  .dialog-subheading {
    @apply text-sm font-light text-monochrome-darker dark:text-monochrome-light tracking-wide pt-1;
  }
}

.denser-forms {
  @include mat.form-field-density(-4);
  @include mat.button-density(-2);
  @include mat.table-density(-2);
}

// *********** Global styles ***********
html,
body {
  @apply h-dvh;
}

// body {
//   margin: 0;
// }

// *** Native components ***
::-webkit-search-cancel-button,
::-webkit-clear-button {
  @apply appearance-none cursor-pointer h-6 w-6 mr-0;

  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  color: rgb(0 0 0 / 54%);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  @apply cursor-pointer text-[0.79rem];
}

@font-face {
  font-family: 'Clan OT';
  src: url('./assets/fonts/Clan OT Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Clan OT';
  src: url('./assets/fonts/Clan OT Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('./assets/fonts/CooperHewitt-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('./assets/fonts/CooperHewitt-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
