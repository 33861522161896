/* stylelint-disable selector-class-pattern */
@use "@angular/material" as mat;
@use "src/assets/scss/variables" as variables;
@use "src/assets/scss/materialThemeColors" as theming;

.success {
  @include mat.theme((color: theming.$success-palette));
}

.error {
  @include mat.theme((color: theming.$error-palette));
  @include mat.icon-button-overrides((
    icon-color: var(--mat-sys-primary),
    ripple-color: var(--mat-sys-on-error),
  ));
}

.neutral {
  @include mat.theme((color: theming.$neutral-palette));
}

.mat-mdc-table {
  .mat-mdc-row {
    @apply hover:bg-monochrome-lighter dark:hover:bg-monochrome-darker #{!important};
  }

  .expanded-element-row {
    @apply cursor-pointer;
  }

  .mat-column-expandedDetail {
    @apply bg-monochrome-lighter/60 dark:bg-monochrome-darker/60 border-x dark:border-x-0 #{!important};
  }

  .mat-sort-header-sorted {
    @apply font-bold tracking-wide;
  }
}

.active-filter-button {
  @apply outline outline-yellow outline-1 #{!important};
  @include mat.icon-button-overrides((icon-color: #{variables.$yellow}));
}

.bold-button {
  --mat-sys-label-large-size: 1rem;
  --mat-sys-label-large-weight: 700;

  @apply tracking-wider;
}

// Overrides
@include mat.sidenav-overrides((
  container-shape: 0,
  container-width: fit-content
));
@include mat.progress-spinner-overrides((
  active-indicator-color: var(--mat-sys-on-surface-variant),
));
@include mat.table-overrides((
  row-item-outline-color: var(--mat-sys-outline-variant)
));
@include mat.button-toggle-overrides((
  selected-state-background-color: var(--mat-sys-primary-container),
  selected-state-text-color: var(--mat-sys-on-primary-container),
));

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
  background-color: var(--mat-sys-surface-container-highest) #{!important};

  & .mdc-list-item__primary-text {
    color: var(--mat-sys-primary) #{!important};
  }
}


// *** Material components ***
// Avoids issues with inherited styles making the icons misaligned
.mdc-icon-button {
  font-family: initial;
}

.mat-mdc-dialog-content {
  @apply max-h-full #{!important};
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(5px);
}

.multiline-tooltip {
  white-space: pre-line !important;

  .mdc-tooltip__surface {
    @apply max-w-fit #{!important};
  }
}

// Help cursor for tooltip triggers
.mat-mdc-tooltip-trigger:not(.mat-mdc-tooltip-disabled) {
  @apply cursor-help;
}

// Avoid weird border on the notch of the outline form field (probably related to conflicting styling between tailwind and material)
.mat-mdc-form-field {
  &.mat-form-field-appearance-outline {
    .mdc-notched-outline__notch {
      @apply border-x-0 #{!important};
    }
  }
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  @apply border border-[--mat-sys-inverse-primary] outline outline-4 outline-[--mat-sys-primary];
}

.user-chipset {
  >.mdc-evolution-chip-set__chips {
    @apply gap-2 #{!important};

    .mat-mdc-chip {
      @apply rounded-lg px-2.5 py-1 #{!important};

      &.role-color {
        @apply truncate bg-transparent border-hidden #{!important};

        &.administrator {
          @apply bg-red-deep text-white #{!important};
        }

        &.back-office {
          @apply bg-red text-white #{!important};
        }

        &.marketing-operator {
          @apply bg-yellow-deep text-black #{!important};
        }

        &.observator {
          @apply bg-yellow-light text-black #{!important};
        }

        &.logistics {
          @apply bg-green-600 text-white #{!important};
        }

        &.chip-group {
          @apply border-2 border-solid border-monochrome-darker dark:border-monochrome-lighter #{!important};
        }
      }
    }
  }
}
